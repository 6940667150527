<template>
    <v-row>
        <v-col cols="12" md="6">
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header" v-if="label">
                {{ label }}
            </h1>
            <v-form
                action="#"
                method="POST"
                @submit.prevent="save"
                :disabled="disabled"
                v-model="valid"
                ref="form"
            >
                <v-card v-if="value.project.reminderRequired" elevation="2" class="mb-4">
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitleReminderMail }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="language.labelSubject"
                                    v-model="reminderMail.subject"
                                    :disabled="disabled"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelEmailText }}</label>
                                <vue-editor
                                    v-model="reminderMail.bodyText"
                                    :editor-toolbar="customToolbar"
                                    :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                        <h4 class="font-weight-regular mt-4">{{language.emailReminder}}</h4>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <custom-date-picker v-model="reminderMail.emailSendDateTime" top/>   
                            </v-col>
                            <v-col cols="12" lg="6">
                                <custom-time-picker v-model="reminderMail.emailSendDateTime"/>     
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card v-if="value.project.reminderSmsRequired" elevation="2" class="mb-4">
                    <v-card-text>
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.titleSMS }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    counter="11"
                                    :maxlength="11"
                                    :label="language.labelSmsFrom"
                                    v-model="reminderMail.smsFrom"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    counter="160"
                                    :maxlength="160"
                                    :label="language.labelSmsText"
                                    v-model="reminderMail.smsBody"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <custom-date-picker v-model="reminderMail.smsSendDateTime" top/>   
                            </v-col>
                            <v-col cols="12" lg="6">
                                <custom-time-picker v-model="reminderMail.smsSendDateTime"/>     
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 d-flex">
                    <v-btn color="primary" @click="previousStep"
                        >{{ language.buttonPrevious }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit"
                        >{{ language.buttonNext }}
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-col cols="12" md="5" class="ml-15">
            <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                Preview
            </h2>
            <v-card class="mt-0 p-4 md-3 preview-card">
                <v-card-text>
                    <v-row justify="center">
                        <preview-project :step="ui.step" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import CustomTimePicker from "@/components/controls/CustomTimePicker";
import CustomDatePicker from "@/components/controls/CustomDatePicker";
import {mapGetters, mapMutations, mapState} from "vuex";
import { VueEditor } from "vue2-editor";
import { getRichTextEditorConfig } from "@/services/RichTextService";
import PreviewProject from '@/components/controls/preview/PreviewProject';

export default {
    name: "ReminderMailStep",
    components: {PreviewProject, VueEditor, CustomTimePicker, CustomDatePicker },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    data() {
        return {
            reminderMail: {
                subject: this.value.reminderMail?.subject || "",
                bodyText: this.value.reminderMail?.bodyText || "",
                smsFrom: this.value.reminderMail?.smsFrom || "",
                smsBody: this.value.reminderMail?.smsBody || "",
                smsSendDateTime: this.value.reminderMail?.smsSendDateTime || 0,
                emailSendDateTime: this.value.reminderMail?.emailSendDateTime || 0,
            },
            projectStartTime: this.value.project.startTime || 0,
            customToolbar: getRichTextEditorConfig(),
            valid: false,
            isEmailTimeFilled: false,
            isEmailDateFilled: false,
            isSmsTimeFilled: false,
            isSmsDateFilled: false,
        };
    },
    computed: {
        ...mapState("intake", ["ui"]),
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        }, 
    },
    watch: {
        currentStep(value, oldValue){
          if (oldValue === (this.index + 1)) {
            this.storeChanges();
          }
        }
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep", "setStep"]),
        save() {
            if (this.$refs.form.validate()) {
                this.nextStep();
            }
        },
        storeChanges(){
            if (this.$refs.form.validate()) {
                if (!this.disabled && this.ui.stepStoringEnabled) {
                    this.$emit("input", {
                        ...this.value,
                        reminderMail: this.reminderMail,
                    });
                }
            } else {
                this.setStep(this.index + 1);
            }
        }
    },
};
</script>

<style scoped>
</style>
