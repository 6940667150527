import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from "@/components/layouts/MainLayout";
import NotFound from "@/components/layouts/NotFound";
import IntakeForm from "@/components/pages/IntakeForm";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: { name: 'Home' },
        component: MainLayout,
        children: [
            {
                path: ':form_id',
                component: IntakeForm,
                name: 'Home',
            },

        ]
    },

    {
        path: '*',
        component: NotFound,
        name: 'NotFound'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
