export const removeDisabledSteps = (disabledSteps, steps) => {
    return disabledSteps.filter(s => steps.indexOf(s) === -1);
}

export const addDisabledSteps = (disabledSteps, steps) => {
    const stepsToAdd = steps.filter(s => disabledSteps.indexOf(s) === -1);
    return [...disabledSteps, ...stepsToAdd];
}

export const updateDisabledSteps = (disabledSteps, stepsToAdd, stepsToRemove, value) => {
    if (!value) {
        return addDisabledSteps(disabledSteps, stepsToAdd);
    }

    return removeDisabledSteps(disabledSteps, stepsToRemove);
}