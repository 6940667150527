import language from "@/services/LanguageService";

const languageStore = {
    namespaced: true,
    getters: {
        language: () => language
    }
}

export default languageStore
