export const NL = {
    code: 'nl',
    buttonNext: 'Volgende',
    buttonPrevious: 'Vorige',
    buttonPreview: 'Voorbeeld',
    buttonDelete: 'Verwijderen',
    buttonSave: 'Opslaan',
    buttonCancel: 'Annuleren',
    buttonAddField: 'Veld toevoegen',
    stepProjectInfo: 'Uw project',
    stepRegistrationForm: 'Registratieformulier',
    stepRegistration: 'Registratie',
    stepRegisterMail: 'Registratiemail',
    stepSplash: 'Welkomstpagina',
    stepSplashQLive: 'Wachtpagina',
    stepReminderMail: 'Herinneringsmail/SMS',
    stepLive: 'Livepagina',
    stepEnd: 'Eindpagina',
    stepPostEventMail: 'E-mail na evenement',
    stepEvaluation: 'Evaluatieformulier',
    sectionPreparationPhase: 'Voorbereidingsfase',
    sectionLivePhase: 'Live fase',
    titleIntakeForm: 'Intakeformulier',
    messageInputSaved: 'Uw input is opgeslagen',
    tooltipDragToReorder: 'Versleep naar gewenste positie',
    labelFieldOptions: 'Veldopties (een per regel)',
    messageFieldsNoLabel: 'Er zijn nog velden zonder naam.',
    questionDeleteField: 'Wilt u het volgende veld verwijderen?',

    cardTitleProjectInfo: 'Projectgegevens',
    labelCompanyName: 'Bedrijfsnaam',
    labelContactPerson: 'Contactpersoon',

    cardTitleProject: 'Uitzending',
    labelWebinarTitle: 'Titel uitzending',
    labelDate: 'Verzenddatum',
    labelDateProjectInfo: 'Datum',
    labelTime: 'Verzendtijd',
    labelTimeProjectInfo: 'Tijd',
    labelEndTime: 'Eindtijd',
    labelDuration: 'Duur (minuten)',
    labelEnableRegistration: 'Registraties',
    labelLanguage: 'Taal',
    tooltipLogo: 'Upload bij voorkeur een .png. De aanbevolen afbeeldingsgrootte is maximaal 600 x 200 pixels.',
    tooltipRegistration: 'Als u registraties aanzet, verzorgen wij voor u een volledige registratieflow, inclusief mailings en show/no show statistieken.',

    cardTitleLanguageChange: "Bevestig taal veranderen",
    labelLanguageChangeOverview: "Door op ja te klikken worden alle teksten en formulieren overschreven met standaardteksten van de gekozen taal.",
    labelLanguageChangeItem1: "",
    labelLanguageChangeItem2: "",
    labelLanguageChangeItem3: "",
    labelLanguageChangeItem4: "",
    labelLanguageChangeItem5: "",
    labelLanguageChangeItem6: "",
    labelLanguageChangeNote: "Alle data, tijdstippen, externe links en geüploade documenten zullen niet overschreven worden. Mocht u deze willen vervangen, pas dit handmatig aan en upload de juiste documenten.",
    labelLanguageChangeQuestion: "Wilt u doorgaan?",
    buttonLanguageChangeConfirm: "Ja, bevestig taal",
    buttonNo: "Nee",

    cardTitleStyling: 'Vormgeving',
    labelHeaderColor: 'Hoofdkleur',
    labelSubheaderColor: 'Steunkleur',
    labelButtonColor: 'Kleur van knoppen',
    labelBackgroundColor: 'Background color',
    labelPrimaryColor: 'Hoofdkleur',
    labelUploadLogo: 'Upload uw logo',

    cardTitleRegistrationForm: 'Registratieformulier',
    cardTitleRegistration: 'Registratie',
    labelHeaderText: 'Header tekst',
    labelBodyText: 'Body tekst',
    labelConfirmationText: 'Registratiebevestiging',
    labelForm: 'Formulier',
    labelFieldName: 'Registratieveld',
    labelRequired: 'Verplicht veld',
    defaultFieldEmail: 'E-mailadres',
    defaultFieldFirstName: 'Voornaam',
    defaultFieldLastName: 'Achternaam',
    labelTextField: 'Tekstveld',
    labelRadioButtons: 'Enkele keuze',
    labelCheckBox: 'Meerkeuze',

    cardTitleRegistrationMail: 'Registratie bevestigingsmail',
    labelSubject: 'Onderwerp',
    labelEmailText: 'Emailtekst',

    cardTitleSplashPage: 'Welkomstpagina',
    labelTitle: 'Titel',
    infoFinishPreparationText: 'Dank u wel. Hiermee is de voorbereidingsfase afgerond. Met deze informatie kunnen we starten met het voorbereiden van de registraties van uw evenement.',
    buttonFinishPreparationPhase: 'Eerste deel versturen',

    cardTitleReminderMail: 'Herinneringsmail',
    labelSmsFrom: 'Afzender',
    labelSmsText: 'SMS Tekst',

    cardTitleLivePage: 'Livepagina',
    labelAboutThisBroadcast: 'Tekst over deze uitzending',
    labelChooseYourOptions: 'Kies uw opties',
    labelOptionPolls: 'Polls',
    labelOptionLiveChat: 'Live chat',
    labelOptionAskAQuestion: 'Stel een vraag',
    labelOfferFileForDownload: 'Downloadbaar bestand aanbieden',
    labelExternalUrlTitle: 'Wilt u een externe pagina toevoegen?',
    labelExternalUrl: 'Externe link URL',
    labelExternalUrlText: 'Externe link tekst',

    cardTitleEndPage: 'Eindpagina',

    cardTitleEvaluationPage: 'Evaluatiepagina',
    labelQuestionField: 'Evaluatievraag',
    infoFinishAllInput: 'Dank u wel. Hiermee heeft u alle benodigde input geleverd. We kunnen nu starten met het opzetten van uw hele evenementportaal\t\t',
    buttonFinalize: 'Verstuur',

    invalidDateError:'Invalid date',
    dateFormatError: 'Correct formaat DD-MM-YYYY',
    timeFormatError: 'Correct formaat HH:MM of HH.MM of HHMM.',
    validationErrorRequired: 'Dit veld is verplicht.',
    labelFormalLanguage: 'Aanspreekvorm:',
    labelFormalLanguageFormal: 'u',
    labelFormalLanguageInformal: 'jij',
    languageDutchFormal: 'Nederlands (u)',
    languageDutchInformal: 'Nederlands (jij)',
    languageEnglish: 'Engels',
    languageGerman: 'Duits',
    labelContactEmail: 'E-mailadres',

    labelUploadDlc: 'Wilt u tijdens de Live-uitzending extra downloadbare content aanbieden, zoals een handout? Upload deze hier.',

    dialogTitleFormSaved: 'Dank u wel voor uw input.',
    paragraphFirstFormSaved: 'We gaan uw uitzending voorbereiden.',
    paragraphSecondFormSaved: 'Het formulier is opgeslagen. U kunt het formulier sluiten, of later terugkomen.',

    dialogFirstPhaseSaved: 'Formulier is verstuurd',
    emailReminder: 'Wanneer wilt u de herinneringsmail versturen?',

    saving: 'Bezig met opslaan...',
    titleSMS: 'SMS herinnering',

    requiredErrorTime: 'Vereist als datum is ingevuld',
    requiredErrorDate: 'Vereist als de tijd is gevuld',

    reminder: 'Herinneringmail',
    customEvaluationRequired: 'Custom evaluatie',
    evaluationRequired: 'Evaluatie',
    askQuestion: 'Stel een vraag',
    askQuestionField: 'E-mailadres voor Stel een vraag',
    postEventMailRequired: 'E-mail na evenement',
    cardTitlePostEventMail: 'E-mail na evenement'
}