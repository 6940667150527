<template>
    <v-row>
        <v-col cols="12" md="6">
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header" v-if="label">
                {{ label }}
            </h1>
            <v-form
                action="#"
                method="POST"
                @submit.prevent="save"
                :disabled="disabled"
                ref="form"
            >
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitlePostEventMail }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="language.labelSubject"
                                    v-model="postEventMail.subject"
                                    :disabled="disabled"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelEmailText }}</label>
                                <vue-editor
                                    v-model="postEventMail.bodyText"
                                    :editor-toolbar="customToolbar"
                                    :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                         <v-row>
                            <v-col cols="12" lg="6">
                                <custom-date-picker v-model="postEventMail.emailSendDateTime" top/>   
                            </v-col>
                            <v-col cols="12" lg="6">
                                <custom-time-picker v-model="postEventMail.emailSendDateTime"/>     
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 d-flex">
                    <v-btn color="primary" @click="previousStep"
                        >{{ language.buttonPrevious }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit"
                        >{{ isLastEnabledStep ? language.buttonFinalize : language.buttonNext }}
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-col cols="12" md="5" class="ml-15">
            <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                Preview
            </h2>
            <v-card class="mt-0 p-4 md-3 preview-card">
                <v-card-text>
                    <v-row justify="center">
                        <preview-project :step="ui.step" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import { getRichTextEditorConfig } from "@/services/RichTextService";
import PreviewProject from '@/components/controls/preview/PreviewProject';
import CustomTimePicker from "@/components/controls/CustomTimePicker";
import CustomDatePicker from "@/components/controls/CustomDatePicker";
export default {
    name: "PostEventMailStep",
    components: {PreviewProject, VueEditor, CustomTimePicker, CustomDatePicker },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    data() {
        return {
            postEventMail: {
                subject: this.value.postEventMail?.subject || "",
                bodyText: this.value.postEventMail?.bodyText || "",
                emailSendDateTime: this.value.postEventMail?.emailSendDateTime || 0,
            },
            customToolbar: getRichTextEditorConfig(),
        };
    },
    computed: {
        ...mapGetters("language", ["language"]),
        ...mapState("intake", ["ui"]),
        ...mapGetters("intake", ["isLastEnabledStep", "currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep", "setStep"]),
        save() {
            if (this.$refs.form.validate()) {
                this.nextStep();
            }
        },
        storeChanges(){
            if (this.$refs.form.validate()) {
                if (!this.disabled && this.ui.stepStoringEnabled) {
                    this.$emit("input", {
                        ...this.value,
                        postEventMail: this.postEventMail,
                    });
                }
            } else {
                this.setStep(this.index + 1);
            }
        }
    },
    watch:{
        currentStep(value, oldValue){
          if (oldValue === (this.index + 1)) {
            this.storeChanges();
          }
        }
    },
};
</script>

<style scoped>
</style>
