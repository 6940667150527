<template>
  <v-app>
      <router-view />
  </v-app>
</template>

<script>
export default {
    created() {
        this.$vuetify.theme.themes.light.primary = "#2977BE";
        this.$vuetify.theme.themes.light.primarydark = "#2B4390";
        this.$vuetify.theme.themes.light.primarylight = "#31B6BB";
        this.$vuetify.theme.themes.light.secondary = "#961E87";
        this.$vuetify.theme.themes.light.secondarydark = "#4F3480";
        this.$vuetify.theme.themes.light.secondarylight = "#C20C7F";
        this.$vuetify.theme.themes.light.accent = "#2977BE";
        this.$vuetify.theme.themes.light.error = "#EE3A23";
        this.$vuetify.theme.themes.light.info = "#23A6DF";
        this.$vuetify.theme.themes.light.success = "#40B649";
        this.$vuetify.theme.themes.light.warning = "#F7A41D";
        this.$vuetify.theme.themes.light.gray1 = "#EEEEEE";
        this.$vuetify.theme.themes.light.medgray1 = "#BBBBBB";
        this.$vuetify.theme.themes.light.medgray2 = "#888888";
        this.$vuetify.theme.themes.light.darkgray = "#454545";
    }
}</script>
