import {v4 as uuidv4} from 'uuid';

class Field {
    constructor(data) {
        this.id = data.id || uuidv4();
        this.type = data.type;
        this.label = data.label;
        this.required = data.required;

        if (data.options) {
            this.options = data.options || '';
        }
    }
}

export default Field;