<template>
    <v-app-bar app
               color="white"
               light
    >
        <v-toolbar-title style="display: flex"
                         class="ml-0 pl-lg-10 mr-8"
        >
            <v-img src="/images/logo-quadia-horizontal-w145.png"
                   class="lighten-4"
                   max-width="145"
                   max-height="100"
            ></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>

<script>

export default {
    name: "AppBar",
}
</script>

<style scoped>
.title-text {
    font-size: 1.2em;
}
</style>