<template>
    <v-snackbar v-model="show"
                :color="color"
                :timeout="timeout"
    >
        <div class="d-flex flex-row">
            <div class="message-content flex-grow-1 flex-shrink-1 pa-2">
                {{ message }}
            </div>
            <div class="message-actions flex-grow-0 flex-shrink-0">
                <v-btn text
                       @click="show = false"
                >Close
                </v-btn>
            </div>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    name: 'SnackBar',
    data() {
        return {
            show: false,
            message: '',
            color: '',
            timeout: -1,
        }
    },

    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'snackbar/showMessage') {
                this.message = state.snackbar.content
                this.color = state.snackbar.type
                this.timeout = state.snackbar.timeout || -1
                this.show = true
            }

            if (mutation.type === 'snackbar/hideMessage') {
                this.show = false;
            }
        })
    }
}
</script>