import Vue from 'vue'
import Vuex from 'vuex'
import intakeStore from "@/store/intake";
import languageStore from "@/store/language";
import snackbarStore from "@/store/snackbar";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    intake: intakeStore,
    language: languageStore,
    snackbar: snackbarStore,
  }
})
