<template>
    <component v-if="!loading"
               :is="intakeComponent" />
    <main-loader v-else />
</template>

<script>


import EventManager from '@/components/pages/types/EventManager';
import QuadiaLive from '@/components/pages/types/QuadiaLive';
import { mapActions, mapState } from 'vuex';
import MainLoader from '@/components/controls/MainLoader';
export default {
    name: "IntakeForm",
    components: {
        MainLoader,
        EventManager,
        QuadiaLive
    },
    data() {
        return {
            loading: true
        }
    },
    computed: {
        ...mapState('intake', ['type']),
        intakeComponent() {
            return this.type;
        }
    },
    methods: {
        ...mapActions('intake', ['fetchIntake'])
    },
    mounted() {
        const {form_id} = this.$route.params;

        this.fetchIntake(form_id)
            .then(() => {
                this.loading = false;
            })
            .catch((e) => {
                if (e.response.status && e.response.status === 404) {
                    this.$router.push({name: "NotFound"});
                }
            });
    }
};
</script>

<style lang="scss">

</style>
