<template>
    <v-row>
        <v-col cols="12"
               md="6"
        >
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header"
                v-if="label"
            >
                {{ label }}
            </h1>
            <v-form
                action="#"
                method="POST"
                @submit.prevent="save"
                :disabled="disabled"
            >
                <v-card elevation="2"
                        class="mb-4"
                >
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitleSplashPage }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelBodyText }}</label>
                                <vue-editor v-model="splash.bodyText"
                                            :editor-toolbar="customToolbar"
                                            :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 px-4 grey--text">
                    {{ language.infoFinishPreparationText }}
                </div>
                <div class="mt-4 d-flex">
                    <v-btn color="primary"
                           @click="goPrevious"
                    >{{
                            language.buttonPrevious
                        }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           type="submit"
                    ><div class="no-transform">{{ language.buttonFinishPreparationPhase }}</div>
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-col cols="12" md="5" class="ml-15">
            <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                Preview
            </h2>
            <v-card class="mt-0 p-4 md-3 preview-card">
                <v-card-text>
                    <v-row justify="center">
                        <preview-project :step="ui.step" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {VueEditor} from 'vue2-editor';
import {getRichTextEditorConfig} from "@/services/RichTextService";
import PreviewProject from '@/components/controls/preview/PreviewProject';

export default {
    name: "SplashStep",
    components: {PreviewProject, VueEditor},
    props: {
        value: {
            type: Object,
            default: () => {
            },
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
            default: () => [],
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    data() {
        return {
            splash: {
                title: this.value.splash?.title || "",
                bodyText: this.value.splash?.bodyText || "",
            },
            customToolbar: getRichTextEditorConfig(),
        };
    },
    watch: {
      currentStep(value, oldValue){
        if (oldValue === (this.index + 1)) {
          this.storeChanges();
        }
      }
    },
    computed: {
        ...mapState("intake", ["intake", "ui"]),
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep", "setStep"]),
        goPrevious() {
            this.previousStep();
        },
        save() {
            this.nextStep();
            this.$emit("firstPhaseDone");
        },
        storeChanges(){
          if (!this.disabled && this.ui.stepStoringEnabled) {
            this.$emit("input", {
              ...this.value,
              splash: this.splash,
            });
          }
        }
    },
};
</script>

<style scoped>
.no-transform{
     text-transform: none;
}
</style>
