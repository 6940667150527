import {EN} from "@/lang/en";
import {NL} from "@/lang/nl";

function getLanguage() {
    const lang = process.env.VUE_APP_LANGUAGE;
    switch (lang) {
        default:
        case 'nl': return {...EN, ...NL};
        case 'en': return EN;
    }
}

export default getLanguage();
