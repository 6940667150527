<template>
    <v-text-field
        v-model="model"
        :label="`Start Time (${startTimeZone})`"
        :rules="rules.concat(timeFormat)"
        prepend-icon="mdi-clock-time-four-outline"
        :disabled="disabled"
        @input="input"
    />
</template>

<script>
    import TimeService from '@/services/TimeService';
    import moment from 'moment-timezone';
    import { mapGetters} from 'vuex';
    import { debounce } from 'lodash';


    export default {
        name: 'CustomTimePicker',
        props: {
            value: {
                type: Number,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: () => false
            },
            rules: {
                type: Array,
                default:() => []
            },
        },
        data(){
            return {
                model: null,
            };
        }, 
        computed:{
            ...mapGetters("language", ["language"]),
            timeFormat() {
                const pattern = /^([01]?\d|2[0-3])[:.]?([0-5]\d)$/;
                return [(v) => (pattern.test(v) || !v) || this.language.timeFormatError];
            },
            startTimeZone() {
                const s = moment(new Date()).tz(TimeService.defaultTimezone).format('YYYY-MM-DD HH:mm');
                return moment.tz(s, TimeService.defaultTimezone).format('z');
            }
        },
        methods:{
            input(value){
                const patternHHMM = /^([01]\d|2[0-3])[:.]?([0-5]\d)$/;
                const patternHMM = /^(\d)[:.]?([0-5]\d)$/;
                if(patternHHMM.test(value)){
                    if(value.indexOf(':') > -1){
                        this.emitData(value);
                    } else if(value.indexOf('.') > -1){
                        const time = `${value.slice(0,2)}:${value.slice(3,6)}`;
                        this.emitData(time);
                    } else {
                        const time = `${value.slice(0,2)}:${value.slice(2,5)}`;
                        this.emitData(time);
                    }
                }
                if(patternHMM.test(value)){
                    if(value.indexOf(':') > -1){
                        const time = `0${value}`;
                        this.emitData(time);
                    } else if(value.indexOf('.') > -1){
                        const time = `0${value.slice(0,1)}:${value.slice(2,4)}`;
                        this.emitData(time);
                    } else {
                        const time = `0${value.slice(0,1)}:${value.slice(1,3)}`;
                        this.emitData(time);
                    }
                }
                if(!value){
                    this.emitData("00:00");
                }
            },
            emitData: debounce(function(startHour){
                const startTime = TimeService.addHoursToDate(this.value, startHour);
                this.$emit('input', startTime);
            },500),
        },
        watch:{
            value(value){
                if(this.model){
                    let date = moment(value, 'X').tz(TimeService.defaultTimezone);
                    this.model = date.format('HH:mm');
                }
            }
        },
        created(){
            if(this.value){
                let date = moment(this.value, 'X').tz(TimeService.defaultTimezone);
                this.model = date.format('HH:mm');            
            }
        }
    };
</script>

