<template>
    <div class="stepper">
        <div class="stepper-buttons d-flex flex-row" :class="{'hides-disabled': hidesDisabled}">
            <template v-for="(n, index) in steps"
            >
                <stepper-item :value="index + 1"
                              :key="`${index + 1}-step`"
                              :current="index + 1 === step"
                              :completed="index + 1 < step"
                              :disabled="isDisabled(index + 1)"
                              v-ripple="{class: 'primary--text'}"
                              @step="setStep"
                >
                    <template v-slot:title>
                        {{ n.label }}
                    </template>
                </stepper-item>
                <v-divider class="my-10 stepper-divider"
                           :class="{'next-disabled': allDisabledAfter(index + 1)}"
                           :key="`divider-${index + 1}`"
                           v-if="n.label !== steps[steps.length - 1].label"
                ></v-divider>
            </template>
        </div>
    </div>
</template>

<script>
import StepperItem from "@/components/controls/stepper/StepperItem";

export default {
    name: "Stepper",
    components: {StepperItem},
    data() {
        return {
            step: this.value
        }
    },
    watch: {
        step(value) {
            this.$emit('input', value);
        }
    },
    props: {
        value: {
            type: Number,
            default: 1
        },
        steps: {
            type: Array,
            default: () => []
        },
        disabledSteps: {
            type: Array,
            default: () => []
        },
        hidesDisabled: Boolean
    },
    methods: {
        setStep(index) {
            this.step = index;
        },
        isDisabled(step) {
            return this.disabledSteps.indexOf(step) > -1;
        },
        allDisabledAfter(step) {
            for (let i = step + 1; i <= this.steps.length; i++) {
                if (!this.isDisabled(i)) {
                    return false;
                }
            }

            return true;
        }
    },
}
</script>

<style scoped>
.stepper {
    user-select: none;
}
.stepper-texts {
    justify-content: space-between;
}
.stepper-text {
    font-size: 0.8rem;
    flex: 0 0 auto;
}

</style>

<style lang="scss">
    @import "src/_scss/components/colors";
    .stepper {
        color: $gray-1;
    }
    .stepper-divider {
        border : 2px solid $med-gray-1;
        border-radius: 3px;
    }
    .hides-disabled {
        .stepper-item.disabled-step {
            display: none !important;
        }

        .stepper-item.disabled-step + .stepper-divider {
            display: none;
        }

        .stepper-divider.next-disabled {
            display: none;
        }
    }
</style>