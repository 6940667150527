<template>
    <v-img class="preview"
           :src="src"
    />
</template>

<script>
import { STEPS } from '@/store/intake/settings';

export default {
    name: "preview-event-manager",
    props: {
        step: Number
    },
    computed: {
        src() {
            return STEPS[this.step - 1].previewSrc;
        }
    }
}
</script>

<style scoped>

</style>