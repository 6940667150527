import language from "@/services/LanguageService";

export const STEPS = [
    {
        component: 'project-info-step',
        label: language.stepProjectInfo,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: '',
    },
    {
        component: 'registration-form-step',
        label: language.stepRegistrationForm,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: '/images/demo/registration-demo-image.png'
    },
    {
        component: 'register-mail-step',
        label: language.stepRegisterMail,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: '/images/demo/email-registration-demo-image.png'
    },
    {
        component: 'splash-step',
        label: language.stepSplash,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: '/images/demo/registration-conf-demo-image.png'
    },
    {
        component: 'reminder-mail-step',
        label: language.stepReminderMail,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: '/images/demo/email-reminder-image.png'
    },
    {
        component: 'live-step',
        label: language.stepLive,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: '/images/demo/livepage-demo-image.png'
    },
    {
        component: 'end-step',
        label: language.stepEnd,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: '/images/demo/endpage-demo-image.png'
    },
    {
        component: 'post-event-mail-step',
        label: language.stepPostEventMail,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: '/images/demo/email-post-event-image.png'
    },
    {
        component: 'evaluation-step',
        label: language.stepEvaluation,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: '/images/demo/evaluation-form-demo-image.png'
    }
];

export const QUADIA_LIVE_STEPS = [
    {
        component: 'project-info-step',
        label: language.stepProjectInfo,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: {
            en: '',
            nl: '',
            de: ''
        }
    },
    {
        component: 'registration-form-step',
        label: language.stepRegistration,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: [
            {
                en: '/images/qlive-demo/register-en.jpg',
                nl: '/images/qlive-demo/register-nl.jpg',
                de: '/images/qlive-demo/register-en.jpg'
            },
            {
                en: '/images/qlive-demo/register-confirm-en.jpg',
                nl: '/images/qlive-demo/register-confirm-nl.jpg',
                de: '/images/qlive-demo/register-confirm-en.jpg'
            }
        ]
    },
    {
        component: 'register-mail-step',
        label: language.stepRegisterMail,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: [{
            en: '/images/qlive-demo/confirmation-mail-en.jpg',
            nl: '/images/qlive-demo/confirmation-mail-nl.jpg',
            de: '/images/qlive-demo/confirmation-mail-en.jpg'
        }]
    },
    {
        component: 'reminder-mail-step',
        label: language.stepReminderMail,
        sectionName: language.sectionLivePhase,
        sectionId: 1,
        previewSrc: [{
            en: '/images/qlive-demo/reminder-mail-en.jpg',
            nl: '/images/qlive-demo/reminder-mail-nl.jpg',
            de: '/images/qlive-demo/reminder-mail-en.jpg'
        }]
    },
    {
        component: 'splash-step',
        label: language.stepSplashQLive,
        sectionName: language.sectionPreparationPhase,
        sectionId: 1,
        previewSrc: [{
            en: '/images/qlive-demo/splash-en.jpg',
            nl: '/images/qlive-demo/splash-nl.jpg',
            de: '/images/qlive-demo/splash-en.jpg'
        }]
    },
    {
        component: 'live-step',
        label: language.stepLive,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: [{
            en: '/images/qlive-demo/live-en.jpg',
            nl: '/images/qlive-demo/live-nl.jpg',
            de: '/images/qlive-demo/live-en.jpg'
        }]
    },
    {
        component: 'end-step',
        label: language.stepEnd,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: [{
            en: '/images/qlive-demo/end-en.jpg',
            nl: '/images/qlive-demo/end-nl.jpg',
            de: '/images/qlive-demo/end-en.jpg'
        }]
    },
    {
        component: 'post-event-mail-step',
        label: language.stepPostEventMail,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: [{
            en: '/images/qlive-demo/end-mail-en.jpg',
            nl: '/images/qlive-demo/end-mail-nl.jpg',
            de: '/images/qlive-demo/end-mail-en.jpg'
        }]
    },
    {
        component: 'evaluation-step',
        label: language.stepEvaluation,
        sectionName: language.sectionLivePhase,
        sectionId: 2,
        previewSrc: [{
            en: '/images/qlive-demo/evaluation-en.jpg',
            nl: '/images/qlive-demo/evaluation-nl.jpg',
            de: '/images/qlive-demo/evaluation-en.jpg'
        }]
    }
];

export const PHASE_ONE_LAST_STEP = 'splash-step'
