<template>
    <v-row class="intake-form event-manager">
        <v-col cols="12"
               class="pt-0"
        >
            <v-row class="sticky-steps no-gutters">
                <v-col cols="12"
                       offset-lg="1"
                       lg="10"
                >
                    <stepper
                        v-model="step"
                        :steps="steps"
                        :key="`s-${step}-${updateKey}`"
                        class="sticky-steps pt-4"
                        :disabled-steps="disabledSteps"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       offset-lg="1"
                       lg="10"
                >
                    <v-tabs-items
                        :value="step - 1"
                        class="transparent-background intake-steps"
                    >
                        <v-tab-item
                            v-for="(step, index) in steps"
                            :key="`step-${step.component}`"
                            class="transparent-background"
                            :transition="false"
                            :reverse-transition="false"
                        >
                            <component
                                :is="step.component"
                                v-model="intakeEditable"
                                :label="broadcastTitle"
                                :section="step.sectionId"
                                :disabledSections="ui.disabledSections"
                                :index="index"
                                @title-updated="titleUpdated"
                                @registration-required-updated="registrationRequiredUpdated"
                                @evaluation-required-updated="evaluationRequiredUpdated"
                                @reminder-required-updated="reminderRequiredUpdated"
                                @post-event-mail-required-updated="postEventMailRequiredUpdated"
                                @firstPhaseDone="firstPhaseDone"
                            ></component>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
            <v-dialog v-model="submitFeedbackDialog"
                      min-width="700"
            >
                <v-card>
                    <v-card-title class="font-weight-regular">
                        {{ language.dialogTitleFormSaved }}
                        <v-spacer/>
                        <v-btn icon
                               @click="closeSubmitFeedback"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <p>{{ language.paragraphFirstFormSaved }}</p>
                        <p>{{ language.paragraphSecondFormSaved }}</p>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="submitFirstPhase"
                      max-width="700"
            >
                <v-card>
                    <v-card-title>
                        {{ language.dialogFirstPhaseSaved }}
                        <v-spacer/>
                        <v-btn icon
                               @click="closeSubmitFeedback"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {STEPS} from "@/store/intake/settings";
import Stepper from "@/components/controls/stepper/Stepper";
import ProjectInfoStep from "@/components/controls/form-steps/ProjectInfoStep";
import RegistrationFormStep from "@/components/controls/form-steps/RegistrationFormStep";
import RegisterMailStep from "@/components/controls/form-steps/RegisterMailStep";
import SplashStep from "@/components/controls/form-steps/SplashStep";
import ReminderMailStep from "@/components/controls/form-steps/ReminderMailStep";
import LiveStep from "@/components/controls/form-steps/LiveStep";
import EndStep from "@/components/controls/form-steps/EndStep";
import PostEventMailStep from "@/components/controls/form-steps/PostEventMailStep";
import EvaluationStep from "@/components/controls/form-steps/EvaluationStep";
import {toastMessage} from "@/services/MessageService";
import { removeDisabledSteps, updateDisabledSteps } from '@/services/StepService';

const REGISTRATION_STEP_REQUIRED = 2;
const REGISTRATION_MAIL_STEP_REQUIRED = 3;
const REMINDER_STEP_REQUIRED = 5;
const POST_EVENT_MAIL_STEP_REQUIRED = 8;
const EVALUATION_STEP_REQUIRED = 9;

export default {
    name: "EventManager",
    components: {
        Stepper,
        ProjectInfoStep,
        RegistrationFormStep,
        RegisterMailStep,
        SplashStep,
        ReminderMailStep,
        LiveStep,
        EndStep,
        PostEventMailStep,
        EvaluationStep,
    },
    data() {
        return {
            loading: true,
            broadcastTitle: "",
            updateKey: 0,
            submitFeedbackDialog: false,
            submitFirstPhase: false,
        };
    },
    mounted() {
        this.oldStep = this.ui.step;
        this.broadcastTitle = this.intakeEditable.project.webinarTitle;

        this.registrationRequiredUpdated(
            this.intake.project.registrationRequired
        );
        this.evaluationRequiredUpdated(
            this.intake.project.evaluationRequired
        );
        this.reminderRequiredUpdated(
            this.intake.project.reminderRequired
        );
        this.postEventMailRequiredUpdated(
            this.intake.project.postEventMailRequired
        );
    },
    computed: {
        ...mapState("intake", ["id", "ui", "intake", "disabledFormSteps"]),
        ...mapGetters("language", ["language"]),
        step: {
            get() {
                return this.ui.step;
            },
            set(value) {
                this.setStep(value);
            },
        },
        intakeEditable: {
            get() {
                return this.intake;
            },
            set(value) {
                this.setIntake(value);
                toastMessage(this.language.saving);
                this.saveState().then(this.stateSaved);
            },
        },
        steps() {
            return STEPS;
        },
        disabledSteps: {
            get() {
                return this.disabledFormSteps;
            },
            set(val) {
                this.setDisabledSteps(val);
            }
        }
    },
    methods: {
        ...mapMutations("intake", [
            "nextStep",
            "setStep",
            "setIntake",
            "setId",
            "setDisabledSteps"
        ]),
        ...mapMutations("snackbar", ["hideMessage"]),
        ...mapActions("intake", ["saveState"]),
        titleUpdated(value) {
            this.broadcastTitle = value;
        },
        registrationRequiredUpdated(value) {
            const stepsToAdd = [
                REGISTRATION_STEP_REQUIRED,
                REGISTRATION_MAIL_STEP_REQUIRED,
                REMINDER_STEP_REQUIRED
            ];
            const stepsToRemove = removeDisabledSteps(stepsToAdd, [REMINDER_STEP_REQUIRED]);
            this.disabledSteps = updateDisabledSteps(this.disabledSteps, stepsToAdd, stepsToRemove, value);
            if (value) {
                this.reminderRequiredUpdated(false);
            }
        },
        evaluationRequiredUpdated(value){
            const managedSteps = [EVALUATION_STEP_REQUIRED];
            this.disabledSteps = updateDisabledSteps(this.disabledSteps, managedSteps, managedSteps, value);
        },
        reminderRequiredUpdated(value){
            const managedSteps = [REMINDER_STEP_REQUIRED];
            this.disabledSteps = updateDisabledSteps(this.disabledSteps, managedSteps, managedSteps, value);
        },
        postEventMailRequiredUpdated(value){
            const managedSteps = [POST_EVENT_MAIL_STEP_REQUIRED];
            this.disabledSteps = updateDisabledSteps(this.disabledSteps, managedSteps, managedSteps, value);
        },
        stateSaved() {
            this.hideMessage();
            if (
                this.ui.step === this.steps.length &&
                this.ui.oldStep === this.ui.step
            ) {
                this.submitFeedbackDialog = true;
            }
            if(this.ui.step > this.steps.length){
                this.submitFeedbackDialog = true;
            }
        },
        closeSubmitFeedback() {
            this.submitFirstPhase = false;
            this.submitFeedbackDialog = false;
        },
        firstPhaseDone(){
            this.submitFirstPhase = true;
        }
    },
}
</script>

<style lang="scss">
@import "src/_scss/components/colors";

.intake-form .v-window {
    overflow: initial !important;
    margin-top: 2rem;
}

.sticky-steps {
    position: sticky;
    top: 64px;
    z-index: 1;
    background-color: #f5f5f5;
    overflow-y: visible;

    &:after {
        content: "";
        box-shadow: 0 1px 2px $med-gray-1;
        border-bottom: 1px solid $med-gray-1;
        position: absolute;
        bottom: 3px;
        height: 1px;
        width: 100%;
    }
}

.transparent-background {
    background-color: transparent !important;
}
</style>