import store from '@/store/index';

/**
 * Use this for customized messages (custom type and custom timeout (default: no timeout))
 * @param message
 * @param type // default: 'grey' - generic
 * @param timeout // default: no timeout
 */
export function toastMessage(message, type = 'med-grey-1', timeout = -1) {
    store.commit('snackbar/hideMessage');
    store.commit('snackbar/showMessage', {
        content: message,
        type: type,
        timeout: timeout
    });
}