export const EN = {
    code: 'en',
    buttonNext: 'Next',
    buttonPrevious: 'Back',
    buttonPreview: 'Preview',
    buttonDelete: 'Delete',
    buttonSave: 'Save',
    buttonCancel: 'Cancel',
    buttonAddField: 'Add field',
    stepProjectInfo: 'Project setup',
    stepRegistrationForm: 'Registration form',
    stepRegistration: 'Registration',
    stepRegisterMail: 'Registration mail',
    stepSplash: 'Landing page (splash)',
    stepSplashQLive: 'Waiting page',
    stepReminderMail: 'Reminder mail',
    stepLive: 'Live page',
    stepEnd: 'End page',
    stepPostEventMail: 'E-mail after event',
    stepEvaluation: 'Evaluation page',
    sectionPreparationPhase: 'Preparation phase',
    sectionLivePhase: 'Live phase',
    titleIntakeForm: 'Intake Form',
    messageInputSaved: 'Input saved',
    tooltipDragToReorder: 'Drag to reorder',
    labelFieldOptions: 'Field options (one per line)',
    messageFieldsNoLabel: 'There are fields with no label.',
    questionDeleteField: 'Would you like to delete the following field?',

    cardTitleProjectInfo: 'Project info',
    labelCompanyName: 'Company name',
    labelContactPerson: 'Contact person',

    cardTitleProject: 'Broadcast',
    labelWebinarTitle: 'Webinar title',
    labelDate: 'Start date',
    labelDateProjectInfo: 'Date',
    labelTime: 'Start time',
    labelTimeProjectInfo: 'Time',
    labelEndTime: 'End time',
    labelDuration: 'Duration (minutes)',
    labelEnableRegistration: 'Enable registration',
    labelLanguage: 'Language',
    tooltipLogo: 'Upload a .png file. The maximum dimensions of the image are 600 x 200 pixels.',
    tooltipRegistration: 'If you enable registrations, we will provide you with a full registration flow, including mailings and show / no show statistics.',

    cardTitleLanguageChange: "Confirm language change",
    labelLanguageChangeOverview: "The following input will be overwritten with the language's default values:",
    labelLanguageChangeItem1: "Registration page text and form",
    labelLanguageChangeItem2: "Registration confirmation page text",
    labelLanguageChangeItem3: "E-mail/SMS contents",
    labelLanguageChangeItem4: "Live page text",
    labelLanguageChangeItem5: "End page text",
    labelLanguageChangeItem6: "Evaluation page text and form",
    labelLanguageChangeNote: "The external link content and downloadable content files will not be reset. Please make sure that you replace them when needed.",
    labelLanguageChangeQuestion: "Do you wish to continue?",
    buttonLanguageChangeConfirm: "Yes, set language",
    buttonNo: "No",

    cardTitleStyling: 'Styling',
    labelHeaderColor: 'Header color',
    labelSubheaderColor: 'Subheader color',
    labelButtonColor: 'Button color',
    labelBackgroundColor: 'Background color',
    labelPrimaryColor: 'Primary color',
    labelUploadLogo: 'Upload logo',

    cardTitleRegistrationForm: 'Registration form',
    cardTitleRegistration: 'Registration',
    labelHeaderText: 'Header text',
    labelBodyText: 'Body text',
    labelConfirmationText: 'Confirmation text',
    labelForm: 'Form',
    labelFieldName: 'Field name',
    labelRequired: 'Required',
    defaultFieldEmail: 'E-mail address',
    defaultFieldFirstName: 'First name',
    defaultFieldLastName: 'Last name',
    labelTextField: 'Text field',
    labelRadioButtons: 'One option',
    labelCheckBox: 'Multiple options',

    cardTitleRegistrationMail: 'Registration mail',
    labelSubject: 'Subject',
    labelEmailText: 'Email text',

    cardTitleSplashPage: 'Splash Page',
    labelTitle: 'Title',
    infoFinishPreparationText: 'Finish preparation info text goes here',
    buttonFinishPreparationPhase: 'Send preparation phase',

    cardTitleReminderMail: 'Reminder mail',
    labelSmsFrom: 'From',
    labelSmsText: 'SMS Text',

    cardTitleLivePage: 'Live page',
    labelAboutThisBroadcast: 'About this broadcast',
    labelChooseYourOptions: 'Choose your options',
    labelOptionPolls: 'Polls',
    labelOptionLiveChat: 'Live chat',
    labelOptionAskAQuestion: 'Ask a Question',
    labelOfferFileForDownload: 'Offer file for download',
    labelExternalUrlTitle: 'Would you like to add an external URL?',
    labelExternalUrl: 'External URL',
    labelExternalUrlText: 'Description of the URL',

    cardTitleEndPage: 'End page',

    cardTitleEvaluationPage: 'Evaluation page',
    labelQuestionField: 'Question field',
    infoFinishAllInput: 'Finish all input info text',
    buttonFinalize: 'Submit',

    invalidDateError:'Invalid date',
    dateFormatError: 'Correct format DD-MM-YYYY',
    timeFormatError: 'Correct format HH:MM or HH.MM or HHMM.',
    validationErrorRequired: 'This field is required',
    labelFormalLanguage: 'Language formality:',
    labelFormalLanguageFormal: 'Formal',
    labelFormalLanguageInformal: 'Informal',
    languageDutchFormal: 'Dutch (formal)',
    languageDutchInformal: 'Dutch (Informal)',
    languageEnglish: 'English',
    languageGerman: 'German',
    labelContactEmail: 'Contact e-mail address',

    labelUploadDlc: 'Would you like to offer downloadable content during your broadcast? Upload here.',

    dialogTitleFormSaved: 'Thank you for submitting your form',
    paragraphFirstFormSaved: 'We will complete setting up your broadcast soon.',
    paragraphSecondFormSaved: 'The form is saved. You can now close the form, or return later.',

    dialogFirstPhaseSaved: 'Tho form is sent',
    emailReminder: 'When do you want to send the reminder email?',

    saving: 'Saving...',
    titleSMS: 'SMS settings',

    requiredErrorTime: 'Required if date is filled',
    requiredErrorDate: 'Required if time is filled',

    reminder: 'Reminder mail',
    customEvaluationRequired: 'Custom evaluation',
    evaluationRequired: 'Evaluation',
    askQuestion: 'Ask a Question',
    askQuestionField: 'E-mail address for Ask a Question',
    postEventMailRequired: 'E-mail after event',
    cardTitlePostEventMail: 'E-mail after event'
}