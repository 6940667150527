<template>
    <v-row>
        <v-col cols="12" md="8" offset-md="2">
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 mb-1 sticky-header">
                {{ label }}
            </h1>
            <v-form
                v-model="stepValid"
                action="#"
                method="POST"
                @submit.prevent="save"
                :disabled="disabled"
                ref="validated"
            >
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6">
                        <h2 class="font-weight-regular my-2 black--text">
                            {{ language.cardTitleProjectInfo }}
                        </h2>
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-text-field
                                    :label="language.labelCompanyName"
                                    v-model="client.companyName"
                                    :rules="companyNameRules"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-text-field
                                    :label="language.labelContactPerson"
                                    v-model="client.contactPerson"
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-text-field
                                    type="email"
                                    :label="language.labelContactEmail"
                                    v-model="client.contactEmail"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.labelEnableRegistration"
                                    v-model="project.registrationRequired"
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.titleSMS"
                                    v-model="project.reminderSmsRequired"
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.labelOptionLiveChat"
                                    v-model="live.options.chat"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.reminder"
                                    v-model="project.reminderRequired"
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.askQuestion"
                                    v-model="project.askQuestionRequired"
                                />
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.evaluationRequired"
                                    v-model="project.evaluationRequired"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-switch
                                    :label="language.postEventMailRequired"
                                    v-model="project.postEventMailRequired"
                                />
                            </v-col>
                            <v-col v-if="project.askQuestionRequired" cols="12" lg="4">
                                <v-text-field
                                    v-model="project.askQuestionEmail"
                                     type="email"
                                    :label="language.askQuestionField"
                                    />

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6">
                        <h2 class="font-weight-regular my-2 black--text">
                            {{ language.cardTitleProject }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="language.labelWebinarTitle"
                                    v-model="project.webinarTitle"
                                    @input="titleUpdated"
                                    :rules="webinarTitleRules"
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="4">
                                <custom-date-picker v-model="project.startTime"
                                    :rules="required"
                                    top
                                />   
                            </v-col>
                            <v-col cols="12" lg="4">
                                <custom-time-picker v-model="project.startTime"
                                    :rules="required"
                                />     
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-text-field
                                    :label="language.labelDuration"
                                    v-model="project.duration"
                                    :rules="durationRules"
                                    type="number"
                                    min="0"
                                    required
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-select :items="languages"
                                          item-text="text"
                                          item-value="value"
                                          v-model="selectedLanguage"
                                          :label="language.labelLanguage"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6">
                        <h2 class="font-weight-regular my-4 black--text">
                            {{ language.cardTitleStyling }}
                        </h2>
                        <v-row>
                            <v-col cols="12" md="6">
                                <color-picker-input
                                    v-model="style.headerColor"
                                    :label="language.labelPrimaryColor"
                                    :disabled="disabled"
                                >
                                    <template v-slot:dialog-title>
                                        Select Header color
                                    </template>
                                    {{ language.labelPrimaryColor }}
                                </color-picker-input>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                    :label="language.labelUploadLogo"
                                    accept="image/*, .eps, .psd"
                                    truncate-length="50"
                                    show-size
                                    :rules="fileSizeRules.concat(logoTypeRules)"
                                    @change="imagePicked"
                                >
                                    <template v-slot:append-outer>
                                        <v-tooltip
                                            max-width="250"
                                            bottom
                                            nudge-left="125"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on">help</v-icon>
                                            </template>
                                            <span>{{
                                                language.tooltipLogo
                                            }}</span>
                                        </v-tooltip>
                                    </template>
                                </v-file-input>
                                <v-img
                                    v-if="!logoUploading"
                                    :src="style.logo"
                                />
                                <v-progress-circular
                                    v-else
                                    indeterminate
                                    color="primary"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit"
                        >{{ language.buttonNext }}
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-dialog max-width="700" v-model="languageChangeWarning.modal">
            <v-card elevation="10">
                <v-card-title>
                    {{language.cardTitleLanguageChange}}
                    <v-spacer/>
                    <v-btn icon
                           @click="cancelLanguageChange"
                    >
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p>{{language.labelLanguageChangeOverview}} </p>
                            <ul v-if="language.code === 'en'">
                                <li>{{ language.labelLanguageChangeItem1 }}</li>
                                <li>{{ language.labelLanguageChangeItem2 }}</li>
                                <li>{{ language.labelLanguageChangeItem3 }}</li>
                                <li>{{ language.labelLanguageChangeItem4 }}</li>
                                <li>{{ language.labelLanguageChangeItem5 }}</li>
                                <li>{{ language.labelLanguageChangeItem6 }}</li>
                            </ul>
                            <p class="mt-4">{{language.labelLanguageChangeNote}}</p>
                            <p >{{language.labelLanguageChangeQuestion}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="text-transform-none">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmLanguageChange">{{language.buttonLanguageChangeConfirm}}</v-btn>
                    <v-btn @click="cancelLanguageChange">{{ language.buttonNo }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import CustomTimePicker from "@/components/controls/CustomTimePicker";
import CustomDatePicker from "@/components/controls/CustomDatePicker";
import ColorPickerInput from "@/components/controls/ColorPickerInput";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { $http, makeQliveAdminUrl } from "@/services/http";
import {fileSizeValid, fileSizeValidationRule, fileTypeValidationRule, logoTypeValidationRule} from "@/services/FileUploadService";

export default {
    name: "ProjectInfoStep",
    components: { ColorPickerInput, CustomTimePicker, CustomDatePicker },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
            default: () => [],
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    mounted() {
        this.$refs.validated.resetValidation();
    },
    data() {
        return {
            client: {
                companyName: this.value.client?.companyName || "",
                contactPerson: this.value.client?.contactPerson || "",
                contactEmail: this.value.client?.contactEmail || "",
            },
            project: {
                webinarTitle: this.value.project?.webinarTitle || "",
                startTime: this.value.project?.startTime || 0,
                duration: this.value.project?.duration || null,
                language: this.value.project?.language || "nl",
                formalLanguage: this.value.project?.formalLanguage || 0,
                registrationRequired:
                    this.value.project?.registrationRequired || false,
                evaluationRequired:
                    this.value.project?.evaluationRequired || false,
                reminderRequired:
                    this.value.project?.reminderRequired|| false,
                reminderSmsRequired:
                    this.value.project?.reminderSmsRequired || false,
                askQuestionRequired:
                    this.value.project?.askQuestionRequired || false,
                askQuestionEmail: this.value.project?.askQuestionEmail || "",
                postEventMailRequired:
                    this.value.project?.postEventMailRequired || false,
            },
            style: {
                headerColor: this.value.style?.headerColor || "rgb(0,0,0)",
                subHeaderColor:
                    this.value.style?.subHeaderColor || "rgb(127,127,127)",
                logo: this.value.style?.logo || null,
            },
            live: {
                title: this.value.live?.title || "",
                bodyText: this.value.live?.bodyText || "",
                downloadableContent: this.value.live?.downloadableContent || "",
                options: {
                    poll: this.value.live?.options?.poll || false,
                    chat: this.value.live?.options?.chat || false,
                },
                externalPageLink: {
                    text: this.value.live?.externalPageLink?.text || "",
                    url: this.value.live?.externalPageLink?.url || "",
                },
            },
            selectedLanguage: this.value.project.language,
            endTimeMenu: false,
            logoUploading: false,
            stepValid: false,
            languageChangeWarning: {
                oldLanguage: this.value.project.language,
                modal: false
            },
        };
    },
    watch: {
        "project.registrationRequired"(value) {
            this.project.reminderRequired = value;
            this.$emit("registration-required-updated", value);
        },
        "project.reminderRequired"(value) {
            if(!value && !this.project.reminderSmsRequired){
                this.$emit("reminder-required-updated", value);
            } else if(value && !this.project.reminderSmsRequired){
                this.$emit("reminder-required-updated", value);
            }
        },
        "project.reminderSmsRequired"(value) {
            if(!value && !this.project.reminderRequired){
                this.$emit("reminder-required-updated", value);
            } else if(value && !this.project.reminderRequired){
                this.$emit("reminder-required-updated", value);
            }
        },
        "project.evaluationRequired"(value) {
            this.$emit("evaluation-required-updated", value);
        },
        "project.postEventMailRequired"(value){
            this.project.postEventMailRequired = value;
            this.$emit("post-event-mail-required-updated", value);
        },
        currentStep(value, oldValue){
          if (oldValue === (this.index + 1)) {
            this.storeChanges();
          }
        },
        selectedLanguage(value) {
            if (this.timestamps.createdAt !== this.timestamps.updatedAt) {
                if (value !== this.languageChangeWarning.oldLanguage) {
                    this.languageChangeWarning.modal = true;
                }
            } else {
                this.confirmLanguageChange();
            }
        },
        "languageChangeWarning.modal"(value) {
            if (!value) {
                this.selectedLanguage = this.project.language;
                return;
            }
        },
        "languageChangeWarning.oldLanguage"() {
            this.languageChangeWarning.modal = false;
        }
    },
    computed: {
        ...mapState("intake", ["ui", 'intake', 'timestamps']),
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
        newClient() {
            return {
                client: {
                    companyName: this.value.client?.companyName || "",
                    contactPerson: this.value.client?.contactPerson || "",
                },
            };
        },
        companyNameRules() {
            return [(v) => !!v || this.language.validationErrorRequired];
        },
        webinarTitleRules() {
            return [(v) => !!v || this.language.validationErrorRequired];
        },
        durationRules() {
            return [(v) => !!v || this.language.validationErrorRequired];
        },
        fileSizeRules() {
            return [files => fileSizeValidationRule(files)]
        },
        fileTypeRules(){
            return [files => fileTypeValidationRule(files)]
        },
        logoTypeRules(){
            return [files => logoTypeValidationRule(files)]
        },
        required(){
            return  [(v) => !!v || this.language.validationErrorRequired];
        },
        languages() {
            return [
                {
                    text: this.language.languageDutchFormal, value: "nlFormal"
                },
                {
                    text: this.language.languageDutchInformal, value: "nlInformal"
                },
                {
                    text: this.language.languageEnglish, value: "en"
                },
                {
                    text: this.language.languageGerman, value: "de"
                },
            ];
        },
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "setStep", "setStepStoringEnabled"]),
        ...mapActions('intake', ['saveState']),
        imagePicked() {
            if (!event.target.files || !event.target.files[0]) {
                this.style.logo = null;
                return;
            }

            if (!fileSizeValid(event.target.files[0])) {
                return;
            }
            this.logoUploading = true;
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append("logo", file, file.name);
            $http
                .post(makeQliveAdminUrl("/intake-api/intakes/logo"), formData)
                .then((response) => {
                    this.style.logo = response.data.url;
                    this.logoUploading = false;
                })
                .catch(() => {
                    this.logoUploading = false;
                });
        },
        save() {
            if (this.$refs.validated.validate()) {
                this.nextStep();
            }
        },
        storeChanges() {
          if (this.$refs.validated.validate()) {
            this.setStepStoringEnabled(true);
            if (!this.disabled) {
              this.$emit("input", {
                ...this.value,
                client: this.client,
                project: this.project,
                style: this.style,
                live: this.live
              });
            }
          }else{
            this.setStepStoringEnabled(false);
            this.setStep(1);
          }
        },
        titleUpdated(value) {
            this.$emit("title-updated", value);
        },
        async confirmLanguageChange() {
            this.project.language = this.selectedLanguage;
            this.languageChangeWarning.oldLanguage = this.project.language;
            this.$emit('language-change');
            this.$emit("input", {
                ...this.value,
                client: this.client,
                project: this.project,
                style: this.style,
            });
        },
        cancelLanguageChange() {
            this.languageChangeWarning.modal = false;
        }
    },
};
</script>

<style scoped lang="scss">
.text-transform-none {
    .v-btn {
        text-transform: none !important;
    }
}
</style>
