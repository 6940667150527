<template>
    <v-row>
        <v-col cols="12" md="6">
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header" v-if="label">
                {{ label }}
            </h1>
            <v-form
                action="#"
                method="POST"
                @submit.prevent="save"
                :disabled="disabled"
            >
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitleLivePage }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <label>{{
                                    language.labelAboutThisBroadcast
                                }}</label>
                                <vue-editor
                                    v-model="live.bodyText"
                                    :editor-toolbar="customToolbar"
                                    :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                        <v-card-subtitle class="font-weight-medium grey--text">
                            {{ language.labelChooseYourOptions }}
                        </v-card-subtitle>
                        <v-card
                            class="d-flex mb-2 rounded-lg"
                            :height="'3.5rem'"
                            outlined
                            tile
                        >
                            <v-card-text
                                >{{ language.labelOptionPolls }}
                            </v-card-text>
                            <v-card-actions>
                                <v-switch
                                    v-model="live.options.poll"
                                ></v-switch>
                            </v-card-actions>
                        </v-card>
                        <v-row>
                            <v-col cols="12">
                                <span>{{
                                    language.labelExternalUrlTitle
                                }}</span>
                                <v-text-field
                                    :label="language.labelExternalUrlText"
                                    v-model="live.externalPageLink.text"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    :label="language.labelExternalUrl"
                                    v-model="live.externalPageLink.url"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-5">
                                <span>{{ language.labelUploadDlc }}</span>
                                <v-file-input
                                    accept="*.pdf,*.docx,*.doc,*.xlsx,*.xls,*.odt,*.ods,*.ppt,*.pptx,*.mp3,*.epub,*.jpg;*.jpeg"
                                    truncate-length="50"
                                    show-size
                                    :rules="fileSizeRules"
                                    @change="filePicked"
                                    v-if="!live.downloadableContent"
                                />
                                <div v-if="!fileUploading">
                                    <div v-if="live.downloadableContent">
                                        <v-divider
                                            class="pt-4 mt-4"
                                        ></v-divider>
                                        <v-icon>attach_file</v-icon>
                                        <a
                                            :href="live.downloadableContent"
                                            target="_blank"
                                            >{{ live.downloadableContent }}</a
                                        >
                                        <v-btn icon @click="filePicked">
                                            <v-icon>clear</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <v-progress-circular
                                    v-else
                                    indeterminate
                                    color="primary"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 d-flex">
                    <v-btn color="primary" @click="previousStep"
                        >{{ language.buttonPrevious }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit"
                        >{{ language.buttonNext }}
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-col cols="12" md="5" class="ml-15">
            <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                Preview
            </h2>
            <v-card class="mt-0 p-4 md-3 preview-card">
                <v-card-text>
                    <v-row justify="center">
                        <preview-project :step="ui.step" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import { getRichTextEditorConfig } from "@/services/RichTextService";
import { $http, makeQliveAdminUrl } from "@/services/http";
import { v4 } from "uuid";
import {fileSizeValid, fileSizeValidationRule} from "@/services/FileUploadService";
import PreviewProject from '@/components/controls/preview/PreviewProject';

export default {
    name: "LiveStep",
    components: {PreviewProject, VueEditor },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
            default: () => [],
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    data() {
        return {
            live: {
                title: this.value.live?.title || "",
                bodyText: this.value.live?.bodyText || "",
                downloadableContent: this.value.live?.downloadableContent || "",
                options: {
                    poll: this.value.live?.options?.poll || false,
                    chat: this.value.live?.options?.chat || false,
                },
                externalPageLink: {
                    text: this.value.live?.externalPageLink?.text || "",
                    url: this.value.live?.externalPageLink?.url || "",
                },
            },
            customToolbar: getRichTextEditorConfig(),
            fileUploading: false,
        };
    },
    watch: {
      currentStep(value, oldValue){
        if (oldValue === (this.index + 1)) {
          this.storeChanges();
        }
      }
    },
    computed: {
        ...mapState("intake", ["ui"]),
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
        fileSizeRules() {
            return [fileSizeValidationRule]
        }
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep"]),
        save() {
            this.nextStep();
        },
        storeChanges(){
          if (!this.disabled && this.ui.stepStoringEnabled) {
            this.$emit("input", {
              ...this.value,
              live: this.live,
            });
          }
        },
        filePicked() {
            if (!event.target.files || !event.target.files[0]) {
                this.live.downloadableContent = null;
                return;
            }

            if (!fileSizeValid(event.target.files[0])) {
                return;
            }

            this.fileUploading = true;
            const file = event.target.files[0];
            const formData = new FormData();
            const fileName = v4() + "-" + file.name;
            formData.append("dlc", file, fileName);
            $http
                .post(makeQliveAdminUrl("/intake-api/intakes/dlc"), formData)
                .then((response) => {
                    this.live.downloadableContent = response.data.url;
                    this.fileUploading = false;
                });
        },
    },
};
</script>

<style scoped>
</style>
