<template>
    <div class="d-flex flex-column stepper-item" :class="classes" @click="setStep">
        <div class="flex-grow-0 font-weight-regular grey--text stepper-text mb-1 text-center">
            <slot name="title">{{ value }}</slot>
        </div>
        <div class="flex-grow-0 text-center">
            <div class="value-dot">
                {{ value }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepperItem",
    props: {
        value: {
            type: Number,
            default: 1,
        },
        current: {
            type: Boolean,
            default: () => false,
        },
        completed: {
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        }
    },
    computed: {
        classes() {
            return {
                'completed': this.completed,
                'current': this.current,
                'disabled-step': this.disabled,
            }
        }
    },
    methods: {
        setStep() {
            if (!this.disabled) {
                this.$emit('step', this.value);
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/_scss/components/colors";
.stepper-item {
    width: 5rem;
    overflow: visible;
    position: relative;
    cursor: pointer;
}

.stepper-text {
    margin-left: -50%;
    left: 50%;
    min-width: 10rem;
    font-size: 0.8rem;
}

.value-dot {
    width: 40px;
    height: 40px;
    line-height: 34px;
    text-align: center;
    background-color: $white;
    border: 4px solid $med-gray-1;
    display: inline-block;
    border-radius: 50%;
    color: $med-gray-2;
}

.current .value-dot {
    border-color: $warning
}

.current .stepper-text {
    color: $black !important;
}

.completed .value-dot {
    border-color: $success;
    background-color: $success;
    color: $white;
}


.disabled-step {
    opacity: 0.5;
    cursor: default;
}
</style>