<template>
    <v-form
        action="#"
        method="POST"
        @submit.prevent="save"
        :disabled="disabled"
    >
        <v-row>
            <v-col cols="12"
                   md="6"
            >
                <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header"
                    v-if="label"
                >
                    {{ label }}
                </h1>
                <v-card elevation="2">
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitleRegistration }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelBodyText }}</label>
                                <vue-editor
                                    v-model="registration.bodyText"
                                    :editor-toolbar="customToolbar"
                                    :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card elevation="2">
                    <v-card-text>
                        <field-editor
                            :disabled="disabled"
                            :label="language.labelForm"
                            v-model="registration.fields"
                        />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="5"
                   class="ml-15"
            >
                <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                    Preview
                </h2>
                <v-card class="mt-0 p-4 md-3 preview-card with-large-margin">
                    <v-card-text>
                        <v-row justify="center">
                            <preview-project :step="ui.step" />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"
                   md="6"
            >
                <v-card elevation="2"
                        class="mt-1"
                >
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.labelConfirmationText }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelBodyText }}</label>
                                <vue-editor v-model="registration.confirmationText"
                                            :editor-toolbar="customToolbar"
                                            :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <div class="mt-4 d-flex">
                    <v-btn color="primary"
                           @click="previousStep"
                    >{{ language.buttonPrevious }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           type="submit"
                    >{{ language.buttonNext }}
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="12"
                   md="5"
                   class="ml-15 relative-position"
            >
                <v-card class="mt-1 p-4 md-3 preview-card">
                    <v-card-text>
                        <v-row justify="center">
                            <preview-project :step="ui.step"
                                             :image-index="1"
                            />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>

</template>

<script>
import FieldEditor from "@/components/controls/FieldEditor";
import { mapGetters, mapMutations, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import { getRichTextEditorConfig } from "@/services/RichTextService";
import PreviewProject from '@/components/controls/preview/PreviewProject';

export default {
    name: "RegistrationFormStep",
    components: {PreviewProject, FieldEditor, VueEditor},
    props: {
        value: {
            type: Object,
            default: () => {
            },
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
            default: () => [],
        },
        index: {
            type: Number,
            default: () => 0,
        },
    },
    data() {
        return {
            registration: {
                bodyText: this.value.registration?.bodyText || "",
                confirmationText: this.value.registration?.confirmationText || "",
                fields: JSON.parse(
                    JSON.stringify(this.value.registration?.fields || [])
                ),
            },
            customToolbar: getRichTextEditorConfig(),
            customMarginTop: 0,
        };
    },
    watch: {
        currentStep(value, oldValue) {
            if (oldValue === (this.index + 1)) {
                this.storeChanges();
            }
        }
    },
    computed: {
        ...mapState("intake", ["ui"]),
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep"]),
        save() {
            this.nextStep();
        },
        storeChanges() {
            if (!this.disabled && this.ui.stepStoringEnabled) {
                this.$emit("input", {
                    ...this.value,
                    registration: this.registration,
                });
            }
        },
        computeCustomMarginTop() {

        }
    },
};
</script>

