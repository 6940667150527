import axios from 'axios';

export const $http = axios.create();

function removeTrailingSlash(site)
{
    return site.replace(/\/$/, "");
}

export const makeQliveAdminUrl = function (path) {
    return removeTrailingSlash(process.env.VUE_APP_API_URL) + path
};
