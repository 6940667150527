<template>
    <div>
        <v-row>
            <v-col>
                <h2 class="font-weight-regular black--text my-4">
                    {{ label }}
                </h2>
            </v-col>
            <v-col cols="3" class="pb-0 pt-9 pl-10 caption">
                <h4>{{ language.labelRequired }}</h4>
            </v-col>
        </v-row>
        <draggable
            :disabled="disabled"
            v-model="fields"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
        >
            <v-card
                v-for="field in fields"
                :key="`field-${field.id}`"
                class="item-row pt-md-4 mt-md-5"
                elevation="1"
                :class="{
                    'field-success':
                        !fieldValidationResult.field &&
                        fieldValidationResult.field !== field,
                    'field-error':
                        fieldValidationResult.field &&
                        fieldValidationResult.field === field,
                }"
                @click="fieldActive(field.id)"
            >
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12" lg="1" sm="1" class="py-0">
                            <div class="d-flex">
                                <div class="flex-grow-0 drag-handle">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                color="grey"
                                                class="mr-4 mt-2"
                                                v-on="on"
                                                >drag_indicator
                                            </v-icon>
                                        </template>
                                        <span>{{
                                            language.tooltipDragToReorder
                                        }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" sm="8" class="py-0">
                            <v-text-field
                                v-model="field.label"
                                class="mt-0"
                                hide-details
                                label="Label"
                                @input="validateFieldsDebounced"
                            />
                        </v-col>
                        <v-col cols="12" lg="3" sm="6" class="py-0">
                            <v-select
                                v-model="field.type"
                                :items="fieldTypeOptions"
                                item-text="label"
                                item-value="value"
                                class="mt-0"
                                hide-details
                                label="Type"
                                @input="validateFieldsDebounced"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="10" lg="1" sm="3">
                            <v-switch
                                v-model="field.required"
                                hide-details
                                class="mt-0 required"
                            />
                        </v-col>
                        <v-col cols="2" lg="1" sm="1" class="pt-2 pb-2">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        :disabled="disabled"
                                        icon
                                        color="error"
                                        v-on="on"
                                        class="mt-0"
                                        @click="deleteField(field)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ language.buttonDelete }}</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            fieldHasOptions(field) && activeField === field.id
                        "
                    >
                        <v-col cols="12">
                            <v-textarea
                                v-model="field.options"
                                :label="language.labelFieldOptions"
                                outlined
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </draggable>
        <v-row class="my-3">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        :disabled="disabled"
                        color="primary"
                        v-on="on"
                        @click="addField"
                        class="ml-4 my-3"
                        small
                    >
                        <v-icon small class="mr-2">add </v-icon>
                        {{ language.buttonAddField }}
                    </v-btn>
                </template>
                <span>{{ language.buttonAddField }}</span>
            </v-tooltip>
        </v-row>
        <v-row>
            <div v-if="!fieldValidationResult.valid" class="mx-4">
                <div
                    class="error--text"
                    v-if="fieldValidationResult.reason === fieldNoLabel"
                >
                    {{ language.messageFieldsNoLabel }}
                </div>
            </div>
        </v-row>

        <v-dialog v-if="fieldToDelete" v-model="deleteModal" max-width="700px">
            <v-card>
                <v-card-title>
                    {{ language.buttonDelete }}
                </v-card-title>
                <v-card-text>
                    <p>{{ language.questionDeleteField }}</p>
                    <p>{{ fieldToDelete.name }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="confirmDeleteField"
                        >{{ language.buttonDelete }}
                    </v-btn>
                    <v-btn @click="closeDeleteModal">{{
                        language.buttonCancel
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";
import {
    FIELD_TYPE_TEXT,
    FIELD_TYPES,
    FIELD_VALIDATION_NO_LABEL,
    fieldHasOptions,
    validateFields,
} from "@/services/FieldService";
import Field from "@/models/Field";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
    name: "FieldEditor",
    components: { draggable },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        label: String,
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            fields: this.value.map((field) => new Field(field)),
            editedField: null,
            optionEditor: false,
            fieldToDelete: null,
            deleteModal: false,
            optionsValid: true,
            optionsValidationReason: "",
            fieldValidationResult: {
                valid: true,
                reason: "",
            },
            activeField: null,
        };
    },
    computed: {
        ...mapGetters("language", ["language"]),
        fieldTypeOptions() {
            return FIELD_TYPES;
        },
        fieldNoLabel() {
            return FIELD_VALIDATION_NO_LABEL;
        },
    },
    watch: {
        fields: {
            deep: true,
            handler(value) {
                this.emitFields(value);
            },
        },
    },
    methods: {
        addField() {
            this.fields.push(
                new Field({
                    id: uuidv4(),
                    type: FIELD_TYPE_TEXT,
                    required: false,
                })
            );
            this.$nextTick(() => {
                this.validateFields();
            });
        },
        fieldHasOptions(field) {
            return fieldHasOptions(field);
        },
        emitFields: debounce(function () {
            this.$emit(
                "input",
                this.fields.map((f) => {
                    const value = {
                        id: f.id,
                        label: f.label,
                        type: f.type,
                        required: f.required,
                    };

                    if (f.options) {
                        value.options = f.options;
                    }

                    return value;
                })
            );
        }),
        getOptionsFieldBadgeColor(field) {
            return (field.options || []).length > 0 ? "info" : "error";
        },
        updateField() {
            this.fields = this.fields.map((f) => {
                if (f.id === this.editedField.id) {
                    return this.editedField;
                }

                return f;
            });

            this.closeOptionsModal();
            this.validateFields();
        },
        deleteField(field) {
            this.activeField = field.id;
            this.fieldToDelete = field;
            this.deleteModal = true;
        },
        closeDeleteModal() {
            this.deleteModal = false;
            this.$nextTick(() => {
                this.fieldToDelete = null;
            });
        },
        confirmDeleteField() {
            this.fields = this.fields.filter(
                (f) => f.id !== this.fieldToDelete.id
            );
            this.closeDeleteModal();
            this.validateFields();
        },
        closeOptionsModal() {
            this.optionEditor = false;
            this.$nextTick(() => {
                this.editedField = null;
            });
        },
        validateFields() {
            this.fieldValidationResult = validateFields(this.fields);
            this.$emit("validate", this.fieldValidationResult);
        },
        validateFieldsDebounced() {
            clearInterval(this.$options.validationInterval);
            this.$options.validationInterval = setTimeout(
                this.validateFields,
                400
            );
        },
        fieldActive(id) {
            this.activeField = id;
        },
    },
    mounted() {
        this.validateFields();
    },
};
</script>

<style scoped
       lang="scss"
>
@import "src/_scss/components/colors";

.item-row {
    background-color: #fff;
    border-left-width: 3px;
    position: relative;

    .drag-handle {
        cursor: grab;
    }

    &.field-success {
        border-left: 3px solid $success;
    }

    &.field-error {
        border-left: 3px solid $error;
    }
}

.required {
    margin-top: 0rem;
    padding: 0rem;
    font-size: 0.74rem !important;
    line-height: 1.18rem;
}

.ghost {
    border: 1px solid $primary;
}
</style>