<template>
    <v-row>
        <v-col cols="12" md="6">
            <h1 class="font-weight-regular py-4 pl-8 mx-n4 px-4 sticky-header" v-if="label">
                {{ label }}
            </h1>
            <v-form
                action="#"
                method="POST"
                @submit.prevent="save"
            >
                <v-card elevation="2" class="mb-4">
                    <v-card-text class="px-6 mb-4">
                        <h2 class="font-weight-regular black--text my-2">
                            {{ language.cardTitleEvaluationPage }}
                        </h2>
                        <v-row>
                            <v-col cols="12">
                                <label>{{ language.labelBodyText }}</label>
                                <vue-editor v-model="evaluation.bodyText"
                                            :editor-toolbar="customToolbar"
                                            :disabled="disabled"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card elevation="2">
                    <v-card-text>
                        <h2 class="font-weight-regular black--text my-2"></h2>
                        <field-editor
                            :label="language.stepEvaluation"
                            v-model="evaluation.fields"
                        />
                    </v-card-text>
                </v-card>
                <div class="mt-4 pl-4 grey--text">
                    {{ language.infoFinishAllInput }}
                </div>
                <div class="mt-4 d-flex">
                    <v-btn color="primary" @click="previousStep"
                        >{{ language.buttonPrevious }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit"
                        >{{ language.buttonFinalize }}
                    </v-btn>
                </div>
            </v-form>
        </v-col>
        <v-col cols="12" md="5" class="ml-15">
            <h2 class="font-weight-regular mx-n4 px-6 py-4 mt-0 sticky-header">
                Preview
            </h2>
            <v-card class="mt-0 p-4 md-3 preview-card">
                <v-card-text>
                    <v-row justify="center">
                        <preview-project :step="ui.step" />
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import FieldEditor from "@/components/controls/FieldEditor";
import {VueEditor} from 'vue2-editor';
import {getRichTextEditorConfig} from "@/services/RichTextService";
import PreviewProject from '@/components/controls/preview/PreviewProject';

export default {
    name: "EvaluationStep",
    components: {PreviewProject, FieldEditor, VueEditor },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        label: {
            type: String,
            default: () => "",
        },
        section: {
            type: Number,
        },
        disabledSections: {
            type: Array,
            default: () => [],
        },
        index: {
          type: Number,
          default: () => 0,
        },
    },
    data() {
        return {
            evaluation: {
                title: this.value.evaluation?.title || "",
                bodyText: this.value.evaluation?.bodyText || "",
                fields: JSON.parse(
                    JSON.stringify(this.value.evaluation?.fields || [])
                ),
            },
            customToolbar: getRichTextEditorConfig(),
        };
    },
    watch:{
      currentStep(value, oldValue){
        if (oldValue === (this.index + 1)) {
          this.storeChanges();
        }
      }
    },
    computed: {
        ...mapGetters("language", ["language"]),
        ...mapGetters("intake", ["currentStep"]),
        ...mapState("intake", ["ui"]),
        disabled() {
            return this.disabledSections.includes(this.section);
        },
    },
    methods: {
        ...mapMutations("intake", ["nextStep", "previousStep"]),
        save() {
            this.nextStep();
        },
        storeChanges(){
          if (!this.disabled && this.ui.stepStoringEnabled) {
            this.$emit("input", {
              ...this.value,
              evaluation: this.evaluation,
            });
          }
        }
    },
};
</script>

<style scoped>
</style>
