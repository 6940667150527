<template>
    <div v-if="src[imageIndex]">
        <v-col cols="12">
            <v-row>
                <img class="preview"
                     :src="src[imageIndex][language]"
                     alt="preview"
                />
            </v-row>
        </v-col>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { QUADIA_LIVE_STEPS as STEPS } from '@/store/intake/settings';

export default {
    name: "preview-quadia-live",
    props: {
        step: Number,
        imageIndex: {
            type: Number,
            default: () => 0
        }
    },
    computed: {
        ...mapState('intake', ['intake']),
        language() {
            if (['nlFormal', 'nlInformal'].indexOf(this.intake.project.language) > -1) {
                return 'nl';
            }
            return this.intake.project.language;
        },
        src() {
            return STEPS[this.step - 1]?.previewSrc || [];
        }
    }
}
</script>

<style scoped>
    .preview {
        width: 100%;
    }
</style>