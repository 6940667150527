export const MAX_FILE_SIZE = 5e6;

export const fileSizeValid = file => !(file.size > MAX_FILE_SIZE)

export const filesAreValid = files => {
    if (!files) {
        return true
    }
    if (typeof files.length === 'undefined') {
        return fileSizeValid(files)
    }

    for (let i = 0; i < files.length - 1 ; ++i) {
        if (!fileSizeValid(files.item(i))) {
            return false;
        }
    }

    return true;
}

export const fileTypeIsValid = files => {
    if (!files) {
        return true
    }
    const fileExtension = files.name.split(".")[1];
    if (files.type === '' && (
        fileExtension === 'pdf' ||
        fileExtension === 'doc' ||
        fileExtension === 'docx'
        )){
        return true
    }
    if (files.type==='application/pdf' || 
        files.type==='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || 
        files.type==='application/msword'){
        return true
    }
    return false;
}

export const logoTypeIsValid = files => {
    if (!files) {
        return true
    }
    const fileExtension = files.name.split(".")[1];
    if (files.type === '' && (
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'png' ||
        fileExtension === 'psd' ||
        fileExtension === 'eps'
        )){
        return true
    }
    if (files.type === 'image/png' || 
        files.type === 'image/jpeg' || 
        files.type === 'image/vnd.adobe.photoshop' ||
        files.type === 'application/x-photoshop' ||
        files.type === 'application/photoshop' ||
        files.type === 'application/psd' ||
        files.type === 'zz-application/zz-winassoc-psd' ||
        files.type === 'image/psd' ||
        files.type === 'image/photoshop' ||
        files.type === 'image/x-photoshop' || 
        files.type === 'image/x-eps' ||
        files.type === 'image/eps' ||
        files.type === 'application/postscript' ||
        files.type === 'application/x-eps' ||
        files.type === 'application/eps'){
        return true
    }

    return false;
}



export const fileSizeValidationRule = files => filesAreValid(files) || 'Maximum file size is 5 MB'

export const fileTypeValidationRule = files => fileTypeIsValid(files) || 'Accepted file types are .pdf, .doc, .docx'

export const logoTypeValidationRule = files => logoTypeIsValid(files) || 'Accepted file types are .png, .jpg, .psd, .eps'


