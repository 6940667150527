import moment from 'moment-timezone';

const TimeService = class {
    constructor(locale) {
        this.defaultTimezone = 'Europe/Amsterdam';
        this.locale = locale || 'en-gb';
    }

    leadingZero(val) {
        let result = '' + val;
        return result.length < 2 ? '0' + result : result;
    }

    toDMYFromISO(dateStr) {
        if (!dateStr) return null;

        const [year, month, day] = dateStr.split('-');
        return `${this.leadingZero(day)}-${this.leadingZero(month)}-${year}`;
    }

    addHoursToDate(startDate, startHour) {
        let currentStartTime = moment(startDate, 'X').tz(this.defaultTimezone).format('YYYY-MM-DD');
        const hours = startHour.split(':')[0];
        const minutes = startHour.split(':')[1];
        currentStartTime = currentStartTime + ' ' + hours + ':' + minutes;
        return moment.tz(currentStartTime, this.defaultTimezone).unix();
    }
}

export default new TimeService();
