import { STEPS } from "@/store/intake/settings";
import { QUADIA_LIVE_STEPS } from '@/store/intake/settings';
import { $http, makeQliveAdminUrl } from "@/services/http";


const intakeStore = {
    namespaced: true,
    state: {
        id: '',
        intake: {
            client: {
                companyName: '',
                contactPerson: '',
                contactEmail: '',
            },
            project: {
                webinarTitle: '',
                startTime: 0,
                duration: 60,
                language: 'nl',
                formalLanguage: 1,
                registrationRequired: false,
                reminderRequired: false,
                reminderSmsRequired: false,
                evaluationRequired: false,
                askQuestionRequired: false,
                askQuestionEmail: '',
                postEventMailRequired: false,
            },
            style: {
                headerColor: 'rgba(0,0,0,1)',
                subHeaderColor: 'rgba(127,127,127,1)',
                logo: null,
            },
            registration: {
                title: '',
                bodyText: '',
                confirmationText: '',
                acceptTocText: '',
                fields: [],
            },
            registerMail: {
                subject: '',
                bodyText: '',
            },
            splash: {
                title: '',
                bodyText: '',
            },
            reminderMail: {
                subject: '',
                bodyText: '',
                smsFrom: '',
                smsBody: '',
                smsSendDateTime: null,
                emailSendDateTime: null,
            },
            live: {
                title: '',
                bodyText: '',
                downloadableContent: null,
                externalPageLink: {
                    text: '',
                    url: ''
                },
                options: {
                    poll: false,
                    chat: false,
                }
            },
            end: {
                title: '',
                bodyText: '',
                downloadableContent: null,
                externalPageLink: {
                    text: '',
                    url: ''
                },
            },
            postEventMail: {
                subject: '',
                bodyText: '',
                emailSendDateTime: null,
            },
            evaluation: {
                title: '',
                bodyText: '',
                fields: [],
            },
        },
        ui: {
            step: 1,
            disabledSections: [], // 1: preparation phase, 2: finalization phase
            oldStep: 1,
            stepStoringEnabled: false
        },
        type: 'event-manager',
        disabledFormSteps: [],
        timestamps: {
            createdAt: 0,
            updatedAt: 0,
        }
    },
    getters : {
        currentStep: state => state.ui?.step || undefined,
        isLastEnabledStep: state => {
            const steps = state.type === 'event-manager' ? STEPS : QUADIA_LIVE_STEPS;
            let step = state.ui.step;
            while (step < steps.length) {
                ++step
                if (state.disabledFormSteps.indexOf(step) === -1) {
                    return false;
                }
            }
            return true;
        }
    },
    mutations: {
        setStep: (state, payload) => {
            state.ui.oldStep = state.ui.step;
            state.ui.step = payload
        },
        setOldStep: (state, payload) => state.ui.oldStep = payload,
        nextStep: (state) => {
            const steps = state.type === 'event-manager' ? STEPS : QUADIA_LIVE_STEPS;
            state.ui.oldStep = state.ui.step;

            let step = state.ui.step;

            if (state.ui.step <= steps.length) {
                ++step;
                while (state.disabledFormSteps.indexOf(step) > -1) {
                    state.disabledFormSteps.indexOf(step);
                    ++step;
                }
                state.ui.step = step;
            }
        },
        previousStep: (state) => {
            state.ui.oldStep = state.ui.step;
            let step = state.ui.step;
            if (state.ui.step > 1) {
                --step;
                while (state.disabledFormSteps.indexOf(step) > -1) {
                    --step;
                }
                state.ui.step = step;
            }
        },
        setIntake: (state, payload) => state.intake = payload,
        setId: (state, payload) => state.id = payload,
        setDisabledSections: (state, payload) => state.ui.disabledSections = payload || [],
        setStepStoringEnabled: (state, payload) => state.ui.stepStoringEnabled = payload,
        setType: (state, payload) => state.type = payload,
        setDisabledSteps: (state, payload) => state.disabledFormSteps = payload,
        setTimestamps: (state, payload) => state.timestamps = {...payload}
    },
    actions: {
        saveState: ({ state }) => {
            return $http.put(makeQliveAdminUrl("/intake-api/intakes/" + state.id), { state });
        },
        fetchIntake: ({ commit }, id) => {
            return $http.get(
                makeQliveAdminUrl("/intake-api/intakes/" + id)
            ).then(response => {
                commit('setIntake', response.data.intake);
                commit('setId', id);
                commit('setStep', response.data.ui?.step || 1);
                commit('setOldStep', response.data.ui?.step || 1);
                commit('setDisabledSections', response.data.ui?.disabledSections);
                commit('setType', response.data.type);
                commit('setTimestamps', response.data.timestamps);
                commit('setStepStoringEnabled', true);
            });
        },
    }
}

export default intakeStore;
