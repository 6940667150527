const cssToRgba = function (value) {
    const list = value.replace('rgb(', '')
        .replace(')')
        .replace(' ', '')
        .split(',');
    const rgba = {
        r: parseInt(list[0] || 0),
        g: parseInt(list[1] || 0),
        b: parseInt(list[2] || 0),
        a: parseFloat(list[3] || 1)
    }

    return rgba;
};


const rgbaToHex = function(value) {
    let r = value.r.toString(16);
    let g = value.g.toString(16);
    let b = value.b.toString(16);
    let a = Math.round(a * 255).toString(16);
  
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
    if (a.length == 1)
      a = "0" + a;
  
    return "#" + r + g + b;
};

const  hexToRgba = function(h) {
    let r = 0, g = 0, b = 0;

    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];

    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    const rgba = {
        r: parseInt(r || 0),
        g: parseInt(g || 0),
        b: parseInt(b || 0),
        a: 1
    }

    return rgba;
  }
  

const rgbaToCss = function (value) {
    return `rgb(${value.r}, ${value.g}, ${value.b})`;
};

export {
    cssToRgba,
    rgbaToCss,
    rgbaToHex,
    hexToRgba
}