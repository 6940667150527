import store from '@/store'

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_CHECKBOX = 'checkbox';
export const FIELD_TYPE_RADIO = 'radio';

export const FIELD_VALIDATION_NO_LABEL = 'no-field-label';

const language = store.getters["language/language"];


export const FIELD_TYPES = [
    {
        label: language.labelTextField,
        value: FIELD_TYPE_TEXT
    },
    {
        label: language.labelCheckBox,
        value: FIELD_TYPE_CHECKBOX
    },
    {
        label: language.labelRadioButtons,
        value: FIELD_TYPE_RADIO
    },
];

export const fieldHasOptions = function (field) {
    return ([
        FIELD_TYPE_RADIO,
        FIELD_TYPE_CHECKBOX,
    ].indexOf(field.type) > -1);
}

export const validateFields = function (fields) {
    const validResult = {
        valid: true,
        reason: '',
    }

    for (let i in fields) {
        if (!fields[i].label) {
            validResult.valid = false;
            validResult.reason = FIELD_VALIDATION_NO_LABEL;
            validResult.field = fields[i];
            break;
        }
    }

    return validResult;
}
