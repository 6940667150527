const RICH_TEXT_EDITOR_CONFIG = [
    [
        'bold',
        'italic',
        'underline'
    ],
    [
        {list: 'ordered'},
        {list: 'bullet'}
    ],
]

export function getRichTextEditorConfig() {
    return JSON.parse(JSON.stringify(RICH_TEXT_EDITOR_CONFIG));
}