<template>
    <div class="d-flex loader-container ported">
        <v-progress-circular indeterminate
                             color="primary"
        />
    </div>
</template>

<script>
export default {
name: "MainLoader"
}
</script>

<style scoped lang="scss">
.loader-container {
    height: calc(100vh - 70px);
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
</style>