<template>
    <div>
        <v-text-field
            :value="colorValue"
            :label="label"
            prepend-icon="colorize"
            @click:prepend="pickColor"
            @click="pickColor"
            readonly
        >
            <template v-slot:prepend-inner>
                <span
                    class="color-sample mr-2"
                    :style="{ backgroundColor: colorValue }"
                    @click="pickColor"
                ></span>
            </template>
        </v-text-field>
        <v-dialog v-model="picker" :max-width="300">
            <v-card>
                <v-card-title>
                    <slot name="dialog-title"> Select color </slot>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                    <v-color-picker mode="rgba" v-model="pickerValue" hide-sliders hide-inputs show-swatches />
                    <v-text-field v-model="colorRgb"/>
                     <v-text-field v-model="colorHex"/>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="colorPickerCancel">Cancel</v-btn>
                    <v-btn color="primary" @click="colorPickerConfirm"
                        >OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { cssToRgba, rgbaToCss, rgbaToHex, hexToRgba } from "@/services/ColorService";
import { debounce } from 'lodash'

export default {
    name: "ColorPickerInput",
    props: {
        value: {
            type: String,
            default: "rgb(255,255,255)",
        },
        label: {
            type: String,
            default: () => "Color",
        },
        disabled: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            picker: false,
            colorValue: this.value,
            pickerValue: cssToRgba(this.value),
        };
    },
    computed:{
        colorRgb:{
                get(){
                    return rgbaToCss(this.pickerValue);
                },
                set(value){
                    this.setPickerRgba(value);
                }
        },
        colorHex:{
                get(){
                    return rgbaToHex(this.pickerValue);
                },
                set(value){
                    this.setPickerHexRgba(value);
                }
        }

    },
    // watch: {
    //     colorRGBA: debounce(function (newVal) {
    //         this.pickerValue = cssToRgba(newVal)
    //     }, 250)
    // },
    methods: {

        setPickerRgba: debounce(function(value){
            this.pickerValue = cssToRgba(value)
        }, 850),

        setPickerHexRgba: debounce(function(value){
            this.pickerValue = hexToRgba(value)
        }, 850),

        colorPickerCancel() {
            this.picker = false;
        },

        colorPickerConfirm() {
            const value = rgbaToCss(this.pickerValue);
            this.colorValue = value;
            this.$emit("input", value);
            this.picker = false;
        },
        pickColor() {
            if (this.disabled) {
                return true;
            }
            this.picker = true;
        },
    },
};
</script>

<style scoped>
.color-sample {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.v-color-picker__controls{
    visibility: hidden !important;
}
.v-color-picker__sliders{
    visibility: hidden !important;
    display: none !important;
}
.v-color-picker__preview{
    visibility: hidden !important;
    display: none !important;
}
</style>
