<template>
    <component :is="component"
               :step="step"
               :image-index="imageIndex"/>
</template>

<script>
import { mapState } from 'vuex';
import PreviewEventManager from '@/components/controls/preview/PreviewEventManager';
import PreviewQuadiaLive from '@/components/controls/preview/PreviewQuadiaLive';

export default {
    name: "preview-project",
    props: {
        step: Number,
        imageIndex: {
            type: Number,
            default: () => 0
        }
    },
    components: {
        PreviewEventManager,
        PreviewQuadiaLive
    },
    computed: {
        ...mapState('intake', ['type']),
        component() {
            return `preview-${this.type}`;
        }
    }
}
</script>

<style scoped>

</style>