var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"font-weight-regular black--text my-4"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),_c('v-col',{staticClass:"pb-0 pt-9 pl-10 caption",attrs:{"cols":"3"}},[_c('h4',[_vm._v(_vm._s(_vm.language.labelRequired))])])],1),_c('draggable',{staticClass:"field-dragger",attrs:{"disabled":_vm.disabled,"ghost-class":"ghost","handle":".drag-handle"},model:{value:(_vm.fields),callback:function ($$v) {_vm.fields=$$v},expression:"fields"}},_vm._l((_vm.fields),function(field){return _c('v-card',{key:("field-" + (field.id)),staticClass:"item-row pt-md-4 mt-md-5",class:{
                'field-success':
                    !_vm.fieldValidationResult.field &&
                    _vm.fieldValidationResult.field !== field,
                'field-error':
                    _vm.fieldValidationResult.field &&
                    _vm.fieldValidationResult.field === field,
            },attrs:{"elevation":"1"},on:{"click":function($event){return _vm.fieldActive(field.id)}}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"1","sm":"1"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-0 drag-handle"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4 mt-2",attrs:{"color":"grey"}},on),[_vm._v("drag_indicator ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.language.tooltipDragToReorder))])])],1)])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"6","sm":"8"}},[_c('v-text-field',{staticClass:"mt-0",attrs:{"hide-details":"","label":"Label"},on:{"input":_vm.validateFieldsDebounced},model:{value:(field.label),callback:function ($$v) {_vm.$set(field, "label", $$v)},expression:"field.label"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"3","sm":"6"}},[_c('v-select',{staticClass:"mt-0",attrs:{"items":_vm.fieldTypeOptions,"item-text":"label","item-value":"value","hide-details":"","label":"Type"},on:{"input":_vm.validateFieldsDebounced},model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}})],1),_c('v-col',{attrs:{"cols":"10","lg":"1","sm":"3"}},[_c('v-switch',{staticClass:"mt-0 required",attrs:{"hide-details":""},model:{value:(field.required),callback:function ($$v) {_vm.$set(field, "required", $$v)},expression:"field.required"}})],1),_c('v-col',{staticClass:"pt-2 pb-2",attrs:{"cols":"2","lg":"1","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-0",attrs:{"disabled":_vm.disabled,"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteField(field)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.language.buttonDelete))])])],1)],1),(
                        _vm.fieldHasOptions(field) && _vm.activeField === field.id
                    )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.language.labelFieldOptions,"outlined":""},model:{value:(field.options),callback:function ($$v) {_vm.$set(field, "options", $$v)},expression:"field.options"}})],1)],1):_vm._e()],1)],1)}),1),_c('v-row',{staticClass:"my-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4 my-3",attrs:{"disabled":_vm.disabled,"color":"primary","small":""},on:{"click":_vm.addField}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("add ")]),_vm._v(" "+_vm._s(_vm.language.buttonAddField)+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.language.buttonAddField))])])],1),_c('v-row',[(!_vm.fieldValidationResult.valid)?_c('div',{staticClass:"mx-4"},[(_vm.fieldValidationResult.reason === _vm.fieldNoLabel)?_c('div',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.language.messageFieldsNoLabel)+" ")]):_vm._e()]):_vm._e()]),(_vm.fieldToDelete)?_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.language.buttonDelete)+" ")]),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.language.questionDeleteField))]),_c('p',[_vm._v(_vm._s(_vm.fieldToDelete.name))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.confirmDeleteField}},[_vm._v(_vm._s(_vm.language.buttonDelete)+" ")]),_c('v-btn',{on:{"click":_vm.closeDeleteModal}},[_vm._v(_vm._s(_vm.language.buttonCancel))])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }